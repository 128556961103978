import { Container, Row, Col, Tab } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/projects/sp-hangman.png";
import projImg2 from "../assets/img/projects/lexica-api-php.png";
import projImg3 from "../assets/img/projects/contao-page-speed-insights-bundle.png";
import projImg4 from "../assets/img/projects/agonyz-portfolio-website.png";
import projImg5 from "../assets/img/projects/npm_packagist_api_client.png";
import projImg6 from "../assets/img/projects/github-readme-packagist-stats.png";
import projImg7 from "../assets/img/projects/github-readme-minesweeper.png";
import projImg8 from "../assets/img/projects/sort-algorithm-visualizer.png";
import projImg9 from "../assets/img/projects/github_workflow_cancel_action.png";
import projImg10 from "../assets/img/projects/github-readme-blackjack.png";
import { useRef, useEffect } from "react";
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export const Projects = () => {
    const projectsCardsDiv = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(projectsCardsDiv.current, {
            y: 200,
            opacity: 0
        }, {
            y: 0,
            opacity: 1,
            duration: 1.5,
            ease: "easeIn",
            scrollTrigger: {
                trigger: projectsCardsDiv.current
            }
        });
    }, []);


    const projects = [
        {
            title: "SP Hangman Game",
            description: "Auf dem Symfony Framework basierendes Galgenmännchen-Spiel, das Api-Aufrufe verwendet, um Daten von der offiziellen South Park-Website zu sammeln.",
            imgUrl: projImg1,
            link: "https://www.sp-hangman.de"
        },
        {
            title: "PHP API client for Lexica.art",
            description: "Ein PHP wrapper für die lexica.art Api, um nach Bildern zu suchen, welche von dem Deep-Learning-Text-to-Image-Modell 'stable diffusion' generiert worden sind.",
            imgUrl: projImg2,
            link: "https://github.com/agonyz/php-lexica-art-api"
        },
        {
            title: "Contao Page Speed Insights Bundle",
            description: "Erweiterungsbundle für das auf dem Symfony basierten Contao CMS, um automatisch Seiteninformationen für die hinterlegten Rootseiten via der Google Page Speed Api zu erhalten.",
            imgUrl: projImg3,
            link: "https://packagist.org/packages/agonyz/contao-page-speed-insights-bundle"
        },
        {
            title: "Portfolio Website",
            description: "Portfolio-Website. Umgesetzt mit dem Javascript Framework React als Frontend und Symfony als Backend Komponente.",
            imgUrl: projImg4,
            link: "https://www.agonyz.de"
        },
        {
            title: "Packagist API Client",
            description: "Packagist API Client als NPM Package basierend auf Typescript.",
            imgUrl: projImg5,
            link: "https://www.npmjs.com/package/@agonyz/packagist-api-client"
        },
        {
            title: "GitHub Readme Packagist Stats",
            description: "Dynamisch generierte Statistiken von Packagist Bundles im SVG-Format für das persönliche GitHub Profil mit automatischen Deployment auf Vercel.",
            imgUrl: projImg6,
            link: "https://github.com/agonyz/github-readme-packagist-stats"
        },
        {
            title: "Github Readme Minesweeper",
            description: "Minesweeper Readme Spiel, welches mithilfe von Python und Github Actions realisiert wurde.",
            imgUrl: projImg7,
            link: "https://github.com/agonyz/agonyz"
        },
        {
            title: "Sort Algorithm Visualizer",
            description: "Tool um verschiedene Sortieralgorithmen und deren Funktionsweise zu visualizieren.",
            imgUrl: projImg8,
            link: "https://agonyz.github.io/sort-algorithm-visualizer"
        },
        {
            title: "Github Action: Workflow Cancel",
            description: "GitHub Action, die es erlaubt, gestartete Workflows zu stoppen, wenn ein anderer Workflow bereits ausgeführt wird.",
            imgUrl: projImg9,
            link: "https://github.com/agonyz/action-workflow-cancel"
        },
        {
            title: "Github Readme Blackjack",
            description: "Blackjack Readme Spiel, welches mithilfe von Typescript und Github Actions realisiert wurde.",
            imgUrl: projImg10,
            link: "https://github.com/agonyz/readme-blackjack"
        },
    ];

    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col size={12}>
                        <h2>Projekte</h2>
                        <p>Ein kleiner Einblick in meine Projekte</p>
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Tab.Content ref={projectsCardsDiv} id="slideInUp">
                                <Tab.Pane eventKey="first">
                                    <Row>
                                        {
                                            projects.map((project, index) => {
                                                return (
                                                    <ProjectCard
                                                        key={index}
                                                        {...project}
                                                    />
                                                )
                                            })
                                        }
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
