import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/Home"
import DataPrivacy from "./pages/DataPrivacy";
import Imprint from "./pages/Imprint";
import Layout from "./components/Layout";
import ErrorPage404 from "./pages/ErrorPage404";

function App() {
  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="data-privacy" element={<DataPrivacy />} />
            <Route path="imprint" element={<Imprint />} />
            <Route path="*" element={<ErrorPage404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
  );
}

export default App;
