import { Container, Row, Col } from "react-bootstrap";

export const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row className="align-items-center">
                    <Col size={12} sm={3}>
                    </Col>
                    <Col size={12} sm={3} className="text-center text-sm-end">
                        <p>© {new Date().getFullYear()} <a target="_blank" rel="noreferrer" href="https://www.github.com/agonyz">agonyz</a></p>
                    </Col>
                    <Col size={12} sm={3} className="text-center text-sm-end">
                        <a href="/data-privacy">Datenschutz</a>
                    </Col>
                    <Col size={12} sm={3} className="text-center text-sm-end">
                        <a href="/imprint">Impressum</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
