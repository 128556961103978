import {Banner} from "../components/Banner";
import {Skills} from "../components/Skills";
import {Projects} from "../components/Projects";
import {Contact} from "../components/Contact";

function Home() {
    return (
        <div className="App">
            <Banner />
            <Skills />
            <Projects />
            <Contact />
        </div>
    );
}

export default Home;
