import React, {useEffect, useRef, useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import contactImg from "../assets/img/quill-hand-drawn.svg";
import {motion, useAnimation } from "framer-motion";
import {Envelope} from "./Svg/Envelope";
import {EnvelopeBackWithStamps} from "./Svg/EnvelopeBackWithStamps";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

export const Contact = () => {
    const drawingHandSvg = useRef(null);
    const contactForm = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(drawingHandSvg.current, {
                opacity: 0,
                y: 200
            }, {
                y: 0,
                opacity: 1,
                duration: 1.5,
                ease: "easeIn",
                scrollTrigger: {
                    trigger: drawingHandSvg.current
                }
            });

        gsap.fromTo(contactForm.current, {
            opacity: 0,
            y: 200
        }, {
            y: 0,
            opacity: 1,
            duration: 1.5,
            ease: "easeIn",
            scrollTrigger: {
                trigger: contactForm.current
            }
        });
    }, []);


    const formInitialDetails = {
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    }
    const [formDetails, setFormDetails] = useState(formInitialDetails);
    const [buttonText, setButtonText] = useState('Senden');
    const [status, setStatus] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState(false);

    const onFormUpdate = (category, value) => {
        setFormDetails({
            ...formDetails,
            [category]: value
        })
        if(formIsValid() === false) {
            setStatus({ formValid: false });
            return;
        }
        setStatus({ formValid: true });
        setFormError(false);
    }

    const formIsValid = () => {
        let status = true;
        const fields = Object.entries(formDetails);
        fields.forEach(([key, value]) => {
            if(value === '') {
                status = false;
            }
        })
        return status;
    }

    /* animation */
    const formAnimation = useAnimation();
    const showEnvelope = useAnimation();
    const showPostalStamp = useAnimation();
    const writeSender = useAnimation();
    const writeMail = useAnimation();
    const writeRecipient = useAnimation();
    const writeRecipientMail = useAnimation();
    const showEnvelopeBackside = useAnimation();
    const envelopeWritingAnimation = useAnimation();

    async function sequence() {
        // form animation
        await formAnimation.start({ opacity: 0, transition: { duration: 1 }, transitionEnd: { display: 'none' } });

        // show backside of envelope
        await showEnvelopeBackside.start({rotateY: 0, display: 'block'});

        // write to letter with message
        await envelopeWritingAnimation.start("visible");

        // hide the envelope
        await showEnvelopeBackside.start({scale: 0.1, transitionEnd: { display: 'none' }});

        // show and write to envelope
        await showEnvelope.start({ display: 'block' });
        await showEnvelope.start({ opacity: 1, transition: { duration: 1 } });
        await showPostalStamp.start({ opacity: 1, transition: { duration: 2 } });
        await writeSender.start("visible");
        await writeMail.start("visible");
        await writeRecipient.start("visible");
        await writeRecipientMail.start("visible");

        // remove envelope from viewport to the right
        await showEnvelope.start({ x: 4000, transition: { duration: 1 }, transitionEnd: { display: 'none' } })
    }

    /* -------------------- */


    const handleSubmit = async (e) => {
        e.preventDefault();
        if(!formIsValid()) {
            setFormError(true);
            return;
        }

        setFormSubmitted(true);

        await sequence();
        setButtonText("Sending...");
        let response = await fetch("https://backend.agonyz.de/api/v1/contact/post", {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=utf-8",
            },
            body: JSON.stringify(formDetails),
        });
        setButtonText("Send");
        setFormDetails(formInitialDetails);
        document.querySelector('form .row').style.minHeight = '90px';
        if (response.status === 200) {
            setStatus({ success: true, message: 'Nachricht wurde erfolgreich übermittelt.'});
        } else {
            setStatus({ success: false, message: 'Etwas hat nicht geklappt. Bitte versuchen Sie es später nochmal.'});
        }
    };




    return (
        <section className="contact" id="connect">
            <Container>
                <Row className="align-items-center">
                    <Col size={12} md={6}>
                        <img ref={drawingHandSvg} id="quill-svg" src={contactImg} alt="vintage quill feather drawing" />
                    </Col>
                    <Col size={12} md={6}>
                        <div>
                            <h2>Kontakt</h2>
                            <form ref={contactForm} onSubmit={handleSubmit}>
                                <Row>
                                    <motion.div
                                        initial={{opacity: 1}}
                                        animate={formAnimation}
                                    >
                                    <Col size={12} sm={6} className="px-1">
                                        <input type="text" value={formDetails.firstName} placeholder="Vorname *" onChange={(e) => onFormUpdate('firstName', e.target.value)} />
                                    </Col>
                                    <Col size={12} sm={6} className="px-1">
                                        <input type="text" value={formDetails.lastName} placeholder="Nachname *" onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                                    </Col>
                                    <Col size={12} sm={6} className="px-1">
                                        <input type="email" value={formDetails.email} placeholder="Email *" onChange={(e) => onFormUpdate('email', e.target.value)} />
                                    </Col>
                                    <Col size={12} sm={12} className="px-1">
                                        <textarea rows="6" value={formDetails.message} placeholder="Nachricht *" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                                        <div className="data-privacy-contact-container">
                                            <input className="data-privacy-checkbox" required type="checkbox" />
                                            <label>Ich habe die <a rel="noreferrer" target="_blank" href="https://www.agonyz.de/data-privacy">Datenschutzhinweise</a> gelesen und bin einverstanden. *</label>
                                        </div>
                                        <button id="send-btn" type="submit"><span>{buttonText}</span></button>
                                        {formError && <p className="form-error-notice">Bitte füllen Sie das Formular vollständig aus.</p>}
                                    </Col>
                                    </motion.div>
                                    <motion.div
                                        initial={{opacity: 0, display: 'none'}}
                                        animate={showEnvelope}
                                        className="envelope2"
                                    >
                                        <EnvelopeBackWithStamps
                                            animationPostalStamp={showPostalStamp}
                                            senderName={formDetails.firstName + " " + formDetails.lastName} animationSenderName={writeSender}
                                            senderMail={formDetails.email} animationSenderMail={writeMail}
                                            recipientName={'agonyz'} animationRecipientName={writeRecipient}
                                            recipientMail={'dev@agonyz.de'} animationRecipientMail={writeRecipientMail}
                                        />
                                    </motion.div>
                                    <motion.div
                                        initial={{display: 'none', rotateY: 90}}
                                        animate={showEnvelopeBackside}
                                    >
                                        <Envelope className="envelope-backside" formSubmitted={formSubmitted} animationName={envelopeWritingAnimation} message={formDetails.message} />
                                    </motion.div>
                                    {
                                        status.message &&
                                        <div id="contact-alert" className={status.success === false ? "alert alert-danger" : "alert alert-success"} role="alert">
                                            {status.message}
                                        </div>
                                    }
                                </Row>
                            </form>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
