import {Container, Row, Col} from "react-bootstrap";
import ReactRotatingText from "react-rotating-text";
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { useRef, useEffect } from "react";

export const Banner = () => {
    const signature = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        gsap.fromTo(signature.current, {
            scale: 0,
            opacity: 0
        }, {
            scale: 1,
            opacity: 1,
            duration: 1.5,
            ease: "easeIn",
            scrollTrigger: {
                trigger: signature.current
            }
        });
    }, []);

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="align-items-center">
                    <Col xs={12} md={6} xl={7}>
                        <div>
                            <span className="tagline">Willkommen auf meinem Portfolio</span>
                            <h1>{`Hallo! Ich bin agonyz.`} <br/><span><ReactRotatingText items={['Web Developer.', 'Symfony Liebhaber.', 'PHP Enthusiast.', 'Poet.']}/></span></h1>
                            <p>
                                Mein Name ist Frank Ficzak und ich bin ein Webentwickler aus Deutschland. Ich arbeite hauptsächlich mit dem Symfony-Framework, der Skriptsprache PHP und beschäftige mich mit der Backend-Entwicklung.
                                Außerdem habe ich Erfahrung im Bereich Hosting, Frontend-Entwicklung und arbeite gerne mit dem Content Management System (CMS) Contao.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={6} xl={5}>
                        <div ref={signature}>
                            <span id="signature">Agonyz</span>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}