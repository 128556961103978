import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container} from "react-bootstrap";

function Imprint() {
    return (
        <section className="imprint" id="imprint">
            <Container>
                <div className="container">
                    <h1>Impressum</h1>

                    <h2>Angaben gemäß § 5 TMG</h2>
                    <p>Frank Ficzak<br/>
                        Wackenroderstraße 6<br/>
                        91056 Erlangen
                    </p>

                    <h2>Kontakt</h2>
                    E-Mail: <a href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x64;&#x65;&#x76;&#x40;&#x61;&#x67;&#x6f;&#x6e;&#x79;&#x7a;&#x2e;&#x64;&#x65;">&#x64;&#x65;&#x76;&#x40;&#x61;&#x67;&#x6f;&#x6e;&#x79;&#x7a;&#x2e;&#x64;&#x65;</a>
                </div>
            </Container>
        </section>
    );
}

export default Imprint;