import Progressbar from "react-js-progressbar";

export const SkillCircle = ({ input, skillName }) => {
    return (
        <div className="item">
            <Progressbar
                input={input}
                pathWidth={10}
                pathColor={['#56ab2f', '#a8e063']}
                trailWidth={20}
                trailColor='#363636'
                textStyle={{fill: '#fff'}}
                size={150}
                animation={{
                    duration: 1500,
                    delay: 0,
                    ease: 'linear'
                }}
            >
            </Progressbar>
            <h5>{skillName}</h5>
        </div>
    )
};