import '../App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container} from "react-bootstrap";

function ErrorPage404() {
    return (
        <section className="error-404" id="error-404">
            <Container>
                <h1>404</h1>
                <h3>Die angeforderte Seite konnte nicht gefunden werden.</h3>
                <h3>Zurück zur <a href="/">Startseite</a></h3>
            </Container>
        </section>
    );
}

export default ErrorPage404;