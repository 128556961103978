import * as React from "react"
import { motion } from "framer-motion";

export const Envelope = (props) => {
    const sentences = reduceMessage(props.message);

    function reduceMessage(message) {
        let finalChunks = [];
        message = message + ' ';
        let chunks = message.match(/.{1,40}(?=[.,!?\s-])|\n(?=\n)/g);
        if(chunks !== null) {
            finalChunks = chunks.map(e => e === "\n" ? '' : e)
        }

        if(finalChunks.length > 11) {
            finalChunks.length = 11;
        }

        return finalChunks;
    }

    const container = {
        hidden: { fillOpacity: 0 },
        visible: (i = 1) => ({
            fillOpacity: 1,
            color: '#262626',
            transition: { staggerChildren: 0.03, delayChildren: 0.04 * i },
        }),
    };

    const child = {
        visible: {
            fillOpacity: 1,
            x: 0,
            y: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
            },
        },
        hidden: {
            fillOpacity: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
            },
        },
    };

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 58 58"
            style={{
                enableBackground: "new 0 0 58 58",
            }}
            xmlSpace="preserve"
        >
            <path
                style={{
                    fill: "#fff",
                }}
                d="M39.741 9 30.861.733a2.733 2.733 0 0 0-3.723 0L18.259 9h21.482z"
            />
            <path
                style={{
                    fill: "#f5edd6",
                }}
                d="M52 9H6v21.571L21 42h16l15-11.429z"
            />
            <motion.g
                variants={container}
                animate={props.animationName}
            >
            {sentences.map((sentence, index) => (
                <motion.text
                    x={8}
                    y={13 + (index * 3)}
                    fontSize={3} fontFamily="Kingtthings_Calligraphica"
                    key={index}
                >
                    {Array.from(sentence).map((character, i) => (
                        <motion.tspan
                            initial={{fillOpacity: 0}}
                            variants={child}
                            key={i * 500}
                        >
                            {character}
                        </motion.tspan>
                    ))}
                </motion.text>
            ))}
            </motion.g>
            <path
                style={{
                    fill: "#fff",
                }}
                d="M6.107 30.653 6 30.571V20.413l-6 5.586V26l6.107 4.653zM52 20.413v10.158l-.208.158h.001L58 26v-.001zM0 58H58L37 42H21z"
            />
            <path
                style={{
                    fill: "#fff",
                }}
                d="M0 58V26l21 16zM58 58V26L37 42z"
            />
        </svg>
    );
};