import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { SkillCircle } from "./SkillCircle";

export const Skills = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 750, min: 0 },
            items: 1
        }
    };

    const skills = [
        {
            name: 'PHP',
            points: 90,
        },
        {
            name: 'Typescript',
            points: 90,
        },
        {
            name: 'Symfony',
            points: 85,
        },
        {
            name: 'NestJS',
            points: 85,
        },
        {
            name: 'React',
            points: 85,
        },
        {
            name: 'Docker',
            points: 80,
        },
        {
            name: 'Hosting',
            points: 70,
        },
    ]

    return (
        <section className="skill" id="skills">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="skill-bx">
                            <h2>Skills</h2>
                            <p>Eine Auflistung meiner Fähigkeiten<br></br></p>
                            <Carousel responsive={responsive} infinite={true}
                                      className="owl-carousel owl-theme skill-slider">
                                {skills.map((element, index) => {
                                    return <SkillCircle input={element.points} skillName={element.name}
                                                        key={index}/>
                                })}
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
